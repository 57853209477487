$color_1: #ffffff;
$background-color_1: rgba(74, 72, 72, 0.8);

.legendsList {
  position: absolute;
  z-index: 5;
  margin: 15px;
  bottom: 18%;
  right: 110px;
  padding-bottom: 10px;
  height: 50vh;
}

.legendsListContainer {
  width: 440px;
  border-radius: 10px;
  padding: 10px;
}
.legendContainer {
  margin: 5px;
  padding: 10px;
  gap: 25px;
}
.legendsListItem {
  font-style: normal;
  font-size: 12px;
  width: 210px;
  color: $color_1;
}
.legendsListCancelItem {
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  color: $color_1;
}
.shapeContainer {
  display: flex;
  > div {
    margin: 3px;
    padding: 5px;
    display: flex;
    align-items: center;
    margin: 1px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
  }
}
.verticalHr {
  height: 100p;
  border: 1px solid #858489;
  border-left: 1px;
}
.title {
  font-weight: 600;
  font-size: 12px;
  font-weight: 600;
}
.circles {
  height: 24;
  width: 24;
  height: 20;
  width: 20;
}
.shapes {
  height: 20;
  width: 20;
  height: 18;
  width: 18;
}
.fontClr {
  color: $color_1;
}
@media screen and (max-width: 480px) {
  .legendsList {
    left: 0;
    right: 0;
    width: initial;
    bottom: 80px;
  }
  .legendsListContainer {
    width: initial;
    border-radius: 10px;
  }
  .legendContainer {
    width: initial;
    flex-direction: column;
    align-items: center;
  }
  .legendsListItem .legendsListCancelItem .title {
    font-size: 10px;
    height: auto;
  }
  .legendsListItem {
    width: 100%;
  }
}
