$color_1: #fff;
$blue_color: #1c5bff;
$black_color: #000000;
.sideMenuContainer {
  height: calc(100vh - 50px);
  top: 50px;
  position: fixed;
  z-index: 6;
  font-size: 14px;
  box-shadow: 2px 1px 4px 0 rgb(0 0 0 / 10%);
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  color: #858489;
}
.menuItem {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  .menuIcons {
    height: 15px;
    padding-left: 40px;
  }
  .menuText {
    flex: 1;
    font-weight: 500;
  }
}
.menuItem.active,
.menuItem:hover {
  color: $blue_color;
}
.logoutBtnParent {
  display: flex;
  padding: 12px;
}
.logoutBtn {
  padding: 3px;
  background: #1245a8;
  border-radius: 8px;
  color: $color_1;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .sideMenuContainer {
    width: 100%;
  }
}
