$color_1: #fff;
$color_2: #232a58;
$background-color_1: #ffffff;
$background-color_2: rgba(0, 0, 0, 0.5);
header {
  width: 100%;
  position: fixed;
  padding: 1.5px 10px;
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 7;
}
.hamburgerIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
#header_logo {
  background: url("../../public/images/header-logo.svg") no-repeat center center;
  background-size: contain;
  width: 80px;
  height: 40px;
  position: absolute;
  left: 3%;
  cursor: pointer;
}
.btn {
  padding: 3px 20px;
  background: #1245a8;
  border-radius: 8px;
  color: $color_1;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}
.pad0130 {
  padding: 1px 15px;
}
#profile_label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: $color_2;
}
.font28 {
  font-size: 28px !important;
}
@media screen and (max-width: 480px) {
  #header_logo {
    left: 8%;
  }
}
