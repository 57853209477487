@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

$color_1: rgb(var(--foreground-rgb));
$color_2: inherit;
$color_3: #fff;
#root {
  height: 100vh;
}
.rootWrapper {
  height: 100vh;
  padding-top: 50px;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-family: "Poppins", sans-serif;
}
body {
  font-family: "Poppins", sans-serif;
  color: $color_1;
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}
a {
  color: $color_2;
  text-decoration: none;
}
.smallBtn,
.mediumBtn {
  font-size: 12px;
  color: #ffffff;
  background-color: #1245a8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.smallBtn {
  padding: 12px 10px;
}
.mediumBtn {
  padding: 8px 16px;
}

.bgLessRedBtn {
  padding: 10px 20px;
  background-color: #ffffff;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.input {
  padding: 9px 10px;
  background: url(../../public/images/icon-search.svg) no-repeat 98% center;
}
.input,
.dropdown {
  width: 100%;
  font-size: 12px;
  color: #858489;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
}
.kqWIVd {
  justify-content: flex-start !important;
  font-size: 15px !important;
}
.rmdp-input {
  padding: 18px 25px !important;
  background-color: #ffffff !important;
  width: 100%;
}
.rmdp-container {
  width: 100%;
}
.btn {
  padding: 6px 25px;
  background: #1245a8;
  border-radius: 8px;
  color: $color_3;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.btnGroup {
  border-radius: 50px;
  background-color: #ffffff;
  border: 1.5px solid #1245a8;
}

.btnGroup button {
  color: #1245a8;
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 50px;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease;
  font-weight: 700;
  font-size: 12px;
}

.btnGroup button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btnGroup:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btnGroup button.active {
  background-color: #1245a8;
  color: #ffffff;
}
.bgLessBlueBtn {
  padding: 4px 10px;
  background-color: $color_3;
  color: #1c5bff;
  border: 1px solid #1c5bff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.dock {
  color: #1245a8;
  position: fixed;
  z-index: 5;
  -webkit-box-shadow: 0px -2px 16px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px -2px 16px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px -2px 16px 0 rgba(0, 0, 0, 0.1);
  top: 20%;
  .dockSection {
    padding: 10px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    min-width: 95px;
    .count {
      font-size: 21px;
      font-weight: 300;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: 12px;
      transition: all 0.2s ease-in-out;
      user-select: none;
      white-space: nowrap;
    }
    .label.active {
      margin: 2px 0;
      border-radius: 50px;
      background-color: #ffffff;
      color: #000;
      padding: 2px 10px;
    }
  }
  .dockSection:last-child {
    border: none;
  }
}

.dock.left {
  left: 0;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
}

.dock.right {
  right: 0;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
.dock.right-bottom {
  right: 0;
  bottom: 10%;
  top: initial;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0px -2px 16px 0 rgba(28, 91, 255, 0.2);
}
.dock.top {
  top: 50px;
  right: initial;
  left: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  box-shadow: 0px -2px 16px 0 rgba(28, 91, 255, 0.2);
  width: 100%;
}
.dock.bottom {
  bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-left: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: initial;
  left: 50%;
  transform: translate(-25%, 0%);
  .dockSection {
    border-bottom: none;
    border-right: 0.5px solid #bfbfbf;
    padding: 3px 10px;
    margin: 10px 0;
  }
}
.pill {
  padding: 5px 15px;
  border-radius: 15px;
  text-transform: capitalize;
  font-size: 11px;
  margin: 10px 0;
  background-color: #ffffff;
  color: #1245a8;
  border: 0.5px solid #1245a8;
  white-space: nowrap;
}

.pill.primary {
  background-color: #1245a8;
  color: #ffffff;
}
.loaderContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 50;

  .loaderImg {
    background: url(../../public/images/loader.gif) no-repeat center center;
    background-size: contain;
    min-width: 200px;
    min-height: 200px;
    filter: grayscale(200%);
    opacity: 0.75;
  }
  .loaderText {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    max-width: 300px;
    width: 100%;
    text-align: center;
    color: #858489;
  }
}
.emptyState {
  width: 30%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
  text-align: center;
  .image {
    background: url("../../public/images/header-logo.svg") no-repeat center
      center;
    background-size: contain;
    min-width: 80px;
    min-height: 80px;
    margin: 10px;
    filter: grayscale(200%);
    opacity: 0.5;
  }
  .title {
    color: #1245a8;
    font-size: 16px;
    line-height: 18px;
    padding: 5px 10px;
  }
  .caption {
    color: #858489;
    font-size: 14px;
    line-height: 16px;
    padding: 5px 10px;
  }
}
.pageNotFoundContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pageNotFoundImage {
  background: url("../../public/images/404.svg") no-repeat center center;
  background-size: contain;
  width: 55%;
  height: 55%;
  margin: 30px;
}

@media screen and (max-width: 480px) {
  .pageNotFoundImage {
    width: 90%;
    height: 30%;
    margin: 15px;
  }
}
.pageTitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #ffffff;
  background-size: 100%;
  color: #000;
  h3 {
    margin-right: 20px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #2e2e2e;
  }
}
.editColor {
  color: #1245a8;
}
.addColor {
  color: #1dac56;
}
.deleteColor {
  color: #ff0000;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0f0f0;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
}
