.alertContainer {
  display: flex;
  gap: 5px;
  position: absolute;
  z-index: 100;
  width: auto;
  height: auto;
  border-radius: 8px;
  position: "absolute";
  bottom: 2%;
  right: 1%;
  margin: 4px;
  box-shadow: 0px 0px 10px 0px #00000052;
  border-radius: 10px;
}
.symbol {
  span {
    padding: 8px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }
}
.alertMsg {
  padding: 8px;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 480px;
}
h4 {
  font-weight: 400;
  font-size: 14px;
}
.alertSymbol {
}
.bg_light_red {
  background-color: #ffe3e3;
  border: 1px solid #ff0000;
  .symbol {
    span {
      background-color: #ff0000;
    }
  }
}
.bg_light_green {
  background-color: #ebffe7;
  border: 1px solid #3f9c35;
  .symbol {
    span {
      background-color: #3f9c35;
    }
  }
}
.bg_light_blue {
  background-color: #e2f1ff;
  border: 1px solid #0e68bc;
  .symbol {
    span {
      background-color: #0e68bc;
    }
  }
}
