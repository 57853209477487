$white_color: white;
.parent {
  background-color: $white_color;
  width: 100%;
}
.dataTable {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.filterContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 5;
  border-top: 1px solid #e0e0e0;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
.filterInput {
  padding: 0 5px;
  color: #000;
  width: 100%;
  min-width: 200px;
}
.customDropDown {
  width: 100%;
}

//expanded Activity log
.expandedParent {
  padding: 10px;
  font-size: 13px;
}
.dateRangeInput {
  input {
    background-color: $white_color;
  }
}

.loadingContainer {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.pageNavContainer {
  color: #000;
  background-color: #ffff;
  display: flex;
  gap: 20px;
  padding: 20px;
  .currentPageNoContainer {
    border: 1px solid #1c5bff;
    padding: 5px 10px;
    border-radius: 8px;
    color: #1c5bff;
  }
}
.gap15px {
  gap: 15px;
  p {
    cursor: pointer;
    font-size: 16px;
  }
}

.activityExpandTable {
  display: flex;
  background-color: #ffffff;
  max-width: fit-content;
  max-height: max-content;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  margin: 16px 16px 16px 64px;
  .cell {
    border: 0.1px solid #1c5bff;
    font-size: 12px;
    padding: 12px;
    width: 100%;
    height: 33.7%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
  }
  h5 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }
  .pill {
    background: #1245a8;
    border-radius: 15px;
    padding: 6px 18px;
    color: #fff;
    border: none;
  }
}
.paginatorContainer {
  color: #858489;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.graphViewBg {
  background-color: #ecf8ff;
}
.viewByInputWidth {
  width: 20% !important;
}
