$color_1: #2e2e2e;
$color_2: #858489;
$color_3: #1245a8;
$color_4: #fff;
$background-color_1: #1245a8;

.deleteNodeContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: auto;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.h1 {
  width: 160px;
  height: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: $color_1;
}
.deleteNode {
  width: 160px;
  height: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  color: #2e2e2e;
}
.title {
  width: 500px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: $color_2;
}
.actionDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 6px;
  color: #d60e0e;
}
.buttonDiscard {
  padding: 0.4rem 3rem;
  background: #ffffff;
  color: $color_3;
  border: none;
  font-size: 13px;
  cursor: pointer;
  border-radius: 8px;
  border: 2px solid #1245a8;
}
.actionButtonsContainer {
  box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: flex-end;
}
.buttonChange {
  padding: 0.5rem 4rem;
  background-color: $background-color_1;
  color: $color_4;
  border: none;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  background: #1245a8;
  border-radius: 8px;
  margin: 10px;
}
@media screen and (max-width: 480px) {
  .deleteNodeContainer {
    width: auto;
    height: auto;
    padding: 12px 10px;
  }
  .h1 {
    width: 100%;
    font-size: 15px;
    line-height: 24px;
  }
  .horizontal {
    width: 100%;
  }
  .deleteNode {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-left: 0px;
    align-items: center;
    flex-direction: row;
  }
  .title {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    justify-content: center;
    align-items: center;
  }
  .actionDiv {
    width: 100%;
    margin-left: 0;
    justify-content: space-between;
    flex-direction: row;
  }
  .buttonDiv {
    width: 100%;
    margin-left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .buttonDiscard {
    width: 100%;
  }
  .buttonChange {
    width: 100%;
    margin: 6px 0;
  }
}
