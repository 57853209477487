$color_1: #858489;
$white_color: white;
.initialSearchBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 110px;
  right: 10px;
  z-index: 5;
  width: 100%;
  min-width: 220px;
  max-width: 22%;
}
.searchContainer {
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 6;
  height: calc(100vh - 50px);
  box-shadow: -2px 1px 4px 0 rgb(0 0 0 / 10%);
  width: 100%;
  min-width: 220px;
  max-width: 23%;
  h4 {
    font-weight: 400;
  }
  .sectionGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
  }
}
.nodeSearchCard {
  box-shadow: 0px 1px 4px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  header {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
  }
  .nodeSearchCardBody {
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
    color: #858489;
    label {
      font-size: 13px;
      color: #1245a8;
      margin-right: 5px;
      font-weight: 600;
      word-wrap: normal;
    }
    span {
      word-wrap: break-word;
    }
    .caret {
      color: #1c5bff;
      padding: 0px 3px;
      font-weight: 700;
    }
    .iconPath {
      font-size: 16px;
      padding: 0px 4px;
      position: relative;
      top: 4px;
    }
    .nodeItem:hover {
      text-decoration: underline;
      color: #0e55e1;
      cursor: pointer;
    }
    .pathContent {
      border-radius: 8px;
      border: 1px solid #dddddd;
      padding: 10px;
      margin-top: 3px;
    }
  }
  .title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #2e2e2e;
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .searchContainer {
    min-width: 220px;
    max-width: 30%;
  }
}
@media screen and (min-width: 480px) and (max-width: 900px) {
  .searchContainer {
    min-width: 220px;
    max-width: 45%;
  }
}
@media screen and (max-width: 480px) {
  .searchContainer {
    max-width: 100%;
  }
}

.width70 {
  width: 70% !important;
  margin-right: 4px;
}
.width30 {
  width: 30% !important;
}
.addToCompare {
  color: #858489;
  display: flex;
  gap: 5px;
  font-size: 14px;
  span {
    display: flex;
  }
}
.levelIndicator {
  background-color: #1245a8;
  padding: 4px 10px;
  border-radius: 25px;
  font-size: 12px;
  margin-bottom: 4px;
  color: #ffffff;
}
.gap80px {
  gap: 80px;
}
.levelInput {
  padding: 9px 10px;
  width: 100%;
  font-size: 12px;
  color: #858489;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
  background-color: #ffffff;
}
.horizontal {
  height: auto;
  width: 100%;
  border-bottom: 1px solid #bfbfbf;
}
.clr1C5BFF {
  color: #1c5bff;
}
// eslint-disable-next-line
