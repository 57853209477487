$color_1: #858489;
$color_2: #2e2e2e;
$color_3: black;
$color_4: #1245a8;
$color_5: #fff;
$color_6: #a4a4a4;
$font_family: "Poppins", sans-serif;
$background-color_1: rgba(0, 0, 0, 0.5);
$background-color_2: #ffffff;
$background-color_3: #1245a8;

.legendsPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 1;
  animation-name: example;
  animation-duration: 0.5s;
  transition: "transform 0.5s ease-out";
}
.cell {
  border: 0.1px solid #1c5bff;
  font-size: 14px;
  padding: 4px;
  width: 100%;
  height: 25%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.actionButton {
  border: none;
  background: none;
  padding: 4px 8px;
  cursor: pointer;
  margin-right: 4px;
}
.edit,
.delete,
.add {
  font-weight: 500;
}
.edit {
  color: #1245a8;
}
.delete {
  color: #ff0000;
}
.add {
  font-size: 16px;
  color: #1dac56;
  height: 34px;
  span {
    margin-right: 4px;
  }
}
.addBtn,
.editBtn,
.deleteBtn {
  border-radius: 6px;
}
.editBtn,
.deleteBtn,
.addBtn {
  margin-right: 10px;
}
.addBtn {
  border: 2px solid #1dac56;
}
.editBtn {
  border: 2px solid #1245a8;
}
.deleteBtn {
  border: 2px solid #ff0000;
}
.nonColorBoxBorder {
  border: 2px solid $color_6;
  border-radius: 8px;
}
.nonColorBox {
  border: 2px solid $color_5;
  border-radius: 6px;
  // margin: 2px;
  width: 100px;
  height: 4vh;
}
.height3vhImportant {
  height: 3vh !important;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: $color_2;
}

.dropdown {
  width: 400px;
  font-size: 12px;
  color: $color_1;
  border-radius: 6px;
  border: 1px solid #bfbfbf;
}
.dropdownIcon {
  margin-right: 10px;
  position: absolute;
  right: 2px;
  top: 9px;
}
.formGroup {
  display: flex;
  margin-bottom: 10px;
  font-style: normal;
  width: 80%;
}
.input[type="text"],
.input[type="color"] {
  font-family: $font_family;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  background-color: $background-color_2;
  height: 30px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  color: $color_3;
  resize: none;
}
.input[type="text"] {
  padding: 7px;
}
// .input[type="color"] {
// }
.title {
  width: 500px;
  height: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: $color_1;
}
.fontBold {
  padding: 0px 3px;
  color: black;
  display: contents;
}
.buttonDiv {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 5px 10px;
  border-radius: 0 0 8px 8px;
}
.buttonChange {
  background-color: $background-color_3;
  color: $color_5;
  border: none;
  border-radius: 0.25rem;
  font-size: 13px;
  cursor: pointer;
  width: 140px;
  height: 30px;
  background: #1245a8;
  border-radius: 8px;
  margin: 8px;
}
.buttonDiscard {
  background: #ffffff;
  color: $color_4;
  border: none;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  width: 140px;
  height: 30px;
  border-radius: 8px;
  border: 2px solid #1245a8;
  margin: 8px;
}
@media screen and (max-width: 480px) {
  .buttonDiv {
    flex-direction: column;
    align-items: center;
  }
  .buttonChange {
    width: 100%;
    font-size: 14px;
    height: 35px;
  }
  .buttonDiscard {
    width: 100%;
    font-size: 14px;
    height: 35px;
  }
  .input[type="text"],
  .input[type="color"] {
    width: 325px;
  }
  .editBtn,
  .deleteBtn {
    margin-right: 2px;
    margin-top: 2px;
  }
  .parent {
    h3 {
      font-size: 20px !important;
    }
  }
  .title {
    width: 300px;
  }
  .actionButton {
    padding: 4px;
  }
}
.dataTable {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.filterContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 5;
  border-top: 1px solid #e0e0e0;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}
