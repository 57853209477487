.graphContainer {
  .chartTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
  }
}
.countCards {
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 4px 50px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gap20px {
  gap: 20px;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  align-items: flex-end;
  position: sticky;
  bottom: 0;
  z-index: 5;
  border-top: 1px solid #e0e0e0;
  width: 100%;
  margin: 0 auto;
}
.filterInput {
  padding: 0 5px;
  color: #000;
  width: 100%;
  min-width: 200px;
}
.customDropDown {
  width: 200px;
}

//expanded Activity log
.expandedParent {
  padding: 10px;
  font-size: 13px;
}
.dateRangeInput {
  input {
    background-color: #ffffff;
  }
}
