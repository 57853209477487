$color_1: #232a58;
$color_2: #fff;

#logo {
  background: url("../../public/images/icon-login-logo.svg") no-repeat center
    center;
  background-size: contain;
}
.verticalHr {
  height: 28px;
  border: 2px solid #858489;
  border-left: 1px;
  border-radius: 1px;
}
.textContainer {
  p {
    font-size: 26px;
    color: #858489;
    margin-bottom: 18px;
  }
}
.footer {
  color: #858489;
  font-size: 12px;
}
