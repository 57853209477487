$color_1: black;
$color_2: #1245a8;
$background-color_1: #ecf8ff;
$background-color_2: white;
$background-color_3: rgba(0, 0, 0, 0.5);

@keyframes example {
  from {
    top: 1000px;
  }
  to {
    top: 50%;
  }
}
@keyframes example1 {
  from {
    bottom: 50%;
  }
  to {
    bottom: 1000px;
  }
}
@keyframes hide {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    top: 0;
    transform: translateX(-50%);
  }
}
.viewport {
  background-color: $background-color_1;
}
.bg {
  width: 100%;
  color: $color_1;
}
.legends,
.showAllNodes {
  position: absolute;
  z-index: 5;
  margin: 15px;
  bottom: 5%;
}

.legends {
  right: 3%;
}
.showAllNodes {
  left: 3%;
}
.legendsBtn {
  cursor: pointer;
  padding: 6px 15px;
  border-radius: 50px;
  border: 1.5px solid #1245a8;
  background-color: $background-color_2;
  color: $color_2;
  font-weight: 700;
  font-size: 12px;
}
.legendsPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 1;
  animation-name: example;
  animation-duration: 0.5s;
  transition: "transform 0.5s ease-out";
}
.actionPopup {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  opacity: 1;
  animation-name: example1;
  animation-duration: 0.5s;
}
.hidePopup {
  animation-name: hide;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.legendsList {
  position: absolute;
  z-index: 5;
  margin: 15px;
  bottom: 18%;
  right: 90px;
  padding-bottom: 10px;
}
